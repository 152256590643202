import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import amazonReportService from '@/http/requests/reports/amazonReportService'
import Pager from '@core/dev-extreme/utils/pager'

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? loadOptions.sort : null
    const pager = new Pager(options)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    let sortOrder = ''
    if (sort) {
      pager.setSortOrder(sort)
      sortOrder = `&sort=${pager.toCreateSortOrder()}`
    }

    const pageableQuery = `${pager.toCreatePageable()}${sortOrder}`
    // const pagebleString = pager.toCreatePageable()
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null
    return amazonReportService
      .getRestockRecommendations(filters, pageableQuery)
      .then(response => {
        const data = response.data
        return {
          data: data.content,
          totalCount: data.totalElements,
        }
      })
  },
})

const dataSource = new DataSource({
  store: store,
})

export {
  dataSource as RestockRecommendationDataSource,
}
