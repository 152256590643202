const data = {
  price: 'Your listed price',
  recommendedReplenishmentQty: 'Recommended replenishment qty',
  recommendedShipDate: 'Recommended ship date',
  unitsSoldLast30Days: 'Units sold in the last 30 days',
  salesLast30Days: 'Amount sold in the last 30 days',
  alert: 'Alert',
  totalDaysOfSupplyAmazon: 'Total Days of Supply (including units from open shipments)',
  unfulfillable: 'The number of units that are in fulfillment centers, but not in a condition ',
  customerOrder: 'The number of units reserved for customer orders',
  fcProcessing: 'The number of units that have been sidelined at a fulfillment center for additional processing',
  fcTransfer: 'The number of units being transferred from one fulfillment center to another',
  available: 'The number of units you have for a SKU in fulfillment centers that can be picked, packed, and shipped',
  inbound: `The number of units that you have for a SKU in a shipment about which you have notified Amazon,
     for which you have provided a tracking number, or that has arrived at a fulfillment center for processing.'`,
  totalUnits: 'Total inventory equals all inventory that is available, shipped, and reserved fulfillment center transfer.',
}

export default {
  getData() {
    return data
  },
}
