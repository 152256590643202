<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 px-0">
        <dx-data-grid
          id="restockRecommendationGrid"
          ref="restockRecommendationDataGridRef"
          :height="setHeight"
          :data-source="RestockRecommendationDataSource"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @option-changed="onOptionChanged"
          @initialized="onInitialized"
          @content-ready="onContentReady"
          @cell-dbl-click="onCellDblClick"
          @toolbar-preparing="onToolbarPreparing($event)"
          @editor-preparing="onEditorPreparing"
        >
          <!--region    ::DataGird base components -->
          <dx-sorting mode="single" />
          <dx-remote-operations :paging="true" :filtering="false" :sorting="true" :summary="true" />
          <dx-column-chooser :enabled="true" mode="select" title="Column Chooser" />
          <dx-search-panel
            :highlight-search-text="true"
            :search-visible-columns-only="true"
            :visible="true"
            :width="200"
            placeholder="Search..."
          />
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
          <!--endregion ::DataGird base components -->
          <dx-column data-field="smallImage" cell-template="imageCellTemplate" :width="100" caption="Image" alignment="center" :allow-sorting="false" />
          <template #imageCellTemplate="{data}">
            <p-lazy-img width="72px" height="72px" :src="data.value || defaultImg" />
          </template>
          <dx-column data-field="suiteNo" caption="Suite #" :width="80" :visible="hasPermission" alignment="center" cell-template="accountCell" />
          <template #accountCell="{ data }">
            <a href="#" @click="onFilterByAccountNoLink($event, data)">
              <span>{{ data.value }}</span>
            </a>
          </template>
          <dx-column data-field="companyName" :visible="hasPermission" caption="Company" :width="140" />
          <dx-column data-field="storeName" :visible="hasPermission" caption="Store" :width="140" />
          <dx-column data-field="title" caption="Title" cell-template="titleCellTemplate" :min-width="240" />
          <template #titleCellTemplate="{data}">
            <span class="dx-product-title text-info" role="button" @click="openProductDetails(data.data)">
              {{ data.value }}
            </span>
          </template>
          <!-- <dx-column data-field="upc" caption="UPC" :width="120" />
          <dx-column data-field="msku" caption="MSKU" :width="120" />
          <dx-column data-field="fnsku" caption="FNSKU" :width="120" /> -->
          <dx-column data-field="asin" caption="ASIN" :width="120" cell-template="asinCellTemplate" />
          <template #asinCellTemplate="{data}">
            <a target="blank" :href="`https://www.amazon.com/gp/product/${data.value}`">
              {{ data.value }}
            </a>
          </template>
          <dx-column data-field="amzRank" :width="80" caption="Ranking" />
          <dx-column data-field="alert" caption="Alert" :width="100" header-cell-template="headerInfo" cell-template="alertCell" />
          <template #alertCell="{ data }">
            <div :class="getCellFormatted(data.value)">
              <span>{{ getCellValueFormatted(data.value) }}</span>
            </div>
          </template>
          <dx-column data-field="recommendedReplenishmentQty" caption="Rec. Qty" :width="100" header-cell-template="headerInfo" />
          <dx-column data-field="recommendedShipDate" caption="Rec. Date" :width="100" header-cell-template="headerInfo" />
          <!-- <dx-column data-field="condition" caption="Condition" :width="90" /> -->
          <dx-column data-field="inbound" caption="Inbound" :width="90" header-cell-template="headerInfo" />
          <dx-column data-field="salesLast30Days" caption="30 Day-Sales" :format="{ type: 'currency', precision: 2 }" :width="125" header-cell-template="headerInfo" />
          <dx-column data-field="unitsSoldLast30Days" caption="30 Day-Units" :width="125" header-cell-template="headerInfo" />
          <dx-column data-field="totalUnits" caption="Total Units" :width="110" header-cell-template="headerInfo" />
          <dx-column data-field="available" caption="Available" :width="100" header-cell-template="headerInfo" />
          <dx-column data-field="customerOrder" caption="Cust. Order" :width="120" header-cell-template="headerInfo" />
          <dx-column data-field="unfulfillable" caption="Unfulfillable" :width="120" header-cell-template="headerInfo" />
          <dx-column data-field="working" caption="Working" :width="80" />
          <dx-column data-field="shipped" caption="Shipped" :width="80" />
          <dx-column data-field="receiving" caption="Receiving" :width="90" />
          <dx-column data-field="totalDaysOfSupply" caption="Supply Days" :width="100" />
          <dx-column data-field="daysOfSupplyAtAmazon" caption="Supply Days Amz" :width="152" header-cell-template="headerInfo" />

          <!-- <dx-master-detail :enabled="true" template="masterDetailTemplate" /> -->
          <!-- <template #masterDetailTemplate="{data: recommendation}">
            <restock-recommendation-detail :detail-data="recommendation" />
          </template>-->
          <template #headerInfo="{ data }">
            <div :id="data.column.dataField">
              <span v-b-tooltip.d100.hover.bottom.v-primary :title="headerInformation[data.column.dataField]">
                {{ data.column.caption }}
              </span>
              <i class="bi bi-question-circle text-warning" />
            </div>
          </template>
          <template #toolbarFilters>
            <div class="d-flex flex-row align-items-center">
              <div v-if="hasPermission" class="mr-1 w-50">
                <dx-util-text-box
                  v-model.trim="accountNo"
                  :show-clear-button="true"
                  mode="text"
                  placeholder="Suite No"
                  @key-down="onFilterByAccountNo"
                  @value-changed="onFilterByAccountNo"
                />
              </div>
              <div class="mr-1 w-50">
                <dx-util-select-box
                  v-model="selectedStore"
                  :data-source="stores"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="searchByFilter"
                />
              </div>
            </div>
          </template>
        </dx-data-grid>
      </div>
      <!--Begin:: Product Details -->
      <product-details :product-id="selectedProductId"
        :show-product="isVisible"
        @close="isVisible=false,selectedProductId=null"
      />
      <!--End:: Product Details -->
    </div>
    <div class="col-md-12 px-0">
      <light-box
        :items="images"
        :index="index"
        :effect="'fade'"
        @close="index = null"
      />
    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import Filters from '@robustshell/mixins/filters'
import SearchFilter from '@/http/models/search-filters/InventorySearchFilter'
import CoolLightBox from 'vue-cool-lightbox'
import { RestockRecommendationDataSource } from './restockRecommendationStore'
// import RestockRecommendationDetail from './RestockRecommendationDetail.vue'
import columnDefinitions from './columnDefinitions'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
    'product-details': () => import('@/views/apps/product/products/components/ProductDetails.vue'),
    'light-box': CoolLightBox,
    // 'restock-recommendation-detail': RestockRecommendationDetail,
  },
  mixins: [GridBase, Filters],
  data() {
    return {
      RestockRecommendationDataSource,
      headerInformation: columnDefinitions.getData(),
      isVisible: false,
      selectedProductId: null,
      filters: {
        query: '',
      },
      accountNo: '',
      images: [],
      index: null,
      selectedAsin: '',
      defaultImg: defaultImageUrl,
    }
  },
  computed: {
    dataGrid() {
      return this.$refs.restockRecommendationDataGridRef.instance
    },
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
  },
  created() {
    this.getStores()
  },
  mounted() {
    this.loadData(false)
  },
  methods: {
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageSize') {
        RestockRecommendationDataSource.pageIndex(0)
        RestockRecommendationDataSource.reload()
      }
    },
    onEditorPreparing(e) {
      if (e.parentType !== 'searchPanel') return
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.filters.query = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.filters.query = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.filters.query = ''
            this.searchByFilter()
          }
        }
      }
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)

      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.loadData(false)
          },
        },
        location: 'after',
      })
    },
    loadData(initial) {
      SearchFilter.setDefaultFilters()
      SearchFilter.itemAction = 'FBA'
      if (initial) {
        const filters = SearchFilter.getFilters()
        RestockRecommendationDataSource.searchValue(filters)
        RestockRecommendationDataSource.load()
      } else {
        SearchFilter.accountNo = this.accountNo
        this.dataGrid.clearFilter()
        const filters = SearchFilter.getFilters()
        RestockRecommendationDataSource.searchValue(filters)
        RestockRecommendationDataSource.reload()
        this.filters.query = SearchFilter.q
      }
    },
    onFilterByAccountNoLink(e, row) {
      this.accountNo = row.value.toString()
      this.getStores(this.accountNo)
      this.searchByFilter()
    },
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.accountNo = e.event.target.value.toString()
          this.getStores(this.accountNo)
          this.searchByFilter()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.accountNo = ''
        this.selectedStore = 0
        this.getStores()
        this.searchByFilter()
      }
    },
    searchByFilter() {
      SearchFilter.accountNo = this.accountNo
      SearchFilter.storeId = this.selectedStore
      SearchFilter.q = this.filters.query

      const filters = SearchFilter.getFilters()
      RestockRecommendationDataSource.searchValue(filters)
      RestockRecommendationDataSource.reload()
    },
    openProductDetails(e) {
      this.selectedProductId = e.productId
      this.isVisible = true
    },
    getCellFormatted(cellValue) {
      if (cellValue === 'out_of_stock') return 'badge badge-danger d-block'
      if (cellValue === 'low_stock') return 'badge badge-warning d-block'
      return 'badge badge-success d-block'
    },
    getCellValueFormatted(cellValue) {
      if (cellValue === 'out_of_stock') return 'NO STOCK'
      if (cellValue === 'low_stock') return 'LOW STOCK'
      return 'OK'
    },
    onCellDblClick(e) {
      if (e.rowType === 'data' && e.column.dataField === 'smallImage') {
        if (e.eventType === 'dxdblclick') {
          if (e.data.largeImage) {
            this.images.length = 0
            this.index = 0
            this.images.push(e.data.largeImage)
          }
        }
      }
    },
  },

}
</script>

<style lang="scss" scoped>

</style>
